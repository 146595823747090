// TODO: Delete me when the old sign up survey stuff is deleted
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../../components/modal/CDataModalV2";
import { CDataTypography } from "../../../../../components/text/CDataTypography";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "reactstrap";
import { SignupReason } from "../../../../../models/Accounts/SignupReason";
import { useState } from "react";
import { useCheckboxManager } from "../../../../../components/useCheckboxManager";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../components/CDataTooltip";
import { signupReasonList } from "src/pages/initialSetup/Setup/Steps/1-SelectConnection/signupReasonList";
import { setAccountSignupReasons } from "./setAccountSignupReasons";
import { IAccountSignupReason } from "src/models/Accounts/IAccountSignupReason";
import { SignupReasonTile } from "./SignupReasonTile";

interface ISignupReasonModal {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

export const SignupReasonModal = (props: ISignupReasonModal) => {
  const { showModal, setShowModal } = props;

  const [otherDetails, setOtherDetails] = useState("");
  const checkboxManager = useCheckboxManager(signupReasonList);

  const isOtherDetailsAtMax =
    checkboxManager.selectedCheckboxes.some(
      (reason) => reason.signupReason === SignupReason.Other,
    ) && otherDetails?.length > 250;

  const { mutateAsync: setSignupReasonsAsync, isPending } = useMutation({
    mutationKey: ["/signupreasons/set"],
    mutationFn: setAccountSignupReasons,
    onSettled: () => {
      setShowModal(false);
    },
    meta: {
      errorMessage: "Failed to set signup reasons due to the following error:",
    },
  });

  async function handleSubmit() {
    const trimmedOtherReason = otherDetails.trim().slice(0, 250); // Just in case the user tries to bypass the length limit

    // Map reasons and append other reason text if other is selected
    const mappedSelections: IAccountSignupReason[] =
      checkboxManager.selectedCheckboxes.map((reason) => ({
        signupReason: reason.signupReason,
        reasonDetails:
          reason.signupReason === SignupReason.Other
            ? trimmedOtherReason
            : undefined,
      }));

    await setSignupReasonsAsync(mappedSelections);
  }

  const isSubmitDisabled =
    !checkboxManager.selectedCheckboxes ||
    checkboxManager.selectedCheckboxes?.length === 0 ||
    isOtherDetailsAtMax;

  const getDisabledTooltip = () => {
    if (!isSubmitDisabled) return null;
    if (isOtherDetailsAtMax) {
      return "Please limit your custom reason to 250 characters or less.";
    } else {
      return "Please make at least one selection.";
    }
  };

  const submitButton = (
    <CDataTooltip type={CDataTooltipType.Dark} title={getDisabledTooltip()}>
      <div>
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Get Started
        </CDataButton>
      </div>
    </CDataTooltip>
  );

  if (!showModal) return null;

  return (
    <CDataModalV2
      className="signup-reason-modal"
      close={() => setShowModal(false)}
      displayed={showModal}
      title={" "} // We want the spacing from the title, but no contents in it
      modalHeaderProps={{ style: { paddingTop: "0" } }}
      modalSize="lg"
      removeBorders={true}
      displayToggleCloseButton={false}
      primaryButton={submitButton}
    >
      <>
        <div hidden={!isPending}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <div className="text-center mb-4">
          <CDataTypography
            variant="typography-variant-headline-1"
            color="typography-color-dark-grey"
            className="mb-2"
          >
            Welcome to Connect Cloud
          </CDataTypography>
          <CDataTypography
            variant="typography-variant-card-title"
            color="typography-color-text-muted"
          >
            What brings you here today? (Select all that apply.)
          </CDataTypography>
        </div>
        <div className="tile-container">
          {signupReasonList.map((reason) => (
            <SignupReasonTile
              key={reason.signupReason}
              reason={reason}
              toggle={checkboxManager.toggleCheckbox}
              isChecked={checkboxManager.selectedCheckboxes.includes(reason)}
              otherDetails={otherDetails}
              setOtherDetails={setOtherDetails}
            />
          ))}
        </div>
      </>
    </CDataModalV2>
  );
};
