// TODO: Delete me when the account signup stuff is deleted
import { IAccountSignupReason } from "src/models/Accounts/IAccountSignupReason";
import { cdataFetch } from "../../../../../api/cdataFetch";

export async function getAccountSignupReasons() {
  return cdataFetch<IAccountSignupReason[]>({
    method: "GET",
    url: "/signupreasons/account",
  });
}
